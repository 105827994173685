/*==================  GOOGLE MAP  ======================*/
$map-height: 370px;
$map-height-767: 250px;
$map-height-479: 200px;

.map {
  &_model{
    height: $map-height;

    img {
      max-width: none !important;
    }

    @media (max-width: 767px) {
      height: $map-height-767;
    }

    @media (max-width: 479px) {
      height: $map-height-479;
    }
  }

  &_locations{
    display: none;
  }
}

